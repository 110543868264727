import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Google as GoogleIcon } from "@mui/icons-material";
import "../../Styles/SignupPage.css";
import { useSignup } from "../../Hooks/useSignup";

function SignupPage() {
  const { error, isLoading, signup } = useSignup();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false); // Add state for terms and conditions
  const [errorTerms, setErrorTerms] = useState(""); // Add state for terms error message
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agreeToTerms) {
      setErrorTerms("You must agree to the terms and conditions.");
      return;
    }

    const success = await signup(firstName, lastName, email, password);

    if (success) {
      navigate("/dashboard");
    }
  };

  return (
    <div className="background">
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2,
            borderRadius: 2,
          }}
        >
          <Typography component="h1" variant="h4" sx={{ color: "#fff" }}>
            Create an account
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 3 }}
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  autoComplete="fname"
                  autoFocus
                  InputLabelProps={{ style: { color: "#fff" } }}
                  InputProps={{
                    style: { color: "#fff" },
                  }}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  InputLabelProps={{ style: { color: "#fff" } }}
                  InputProps={{
                    style: { color: "#fff" },
                  }}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  InputLabelProps={{ style: { color: "#fff" } }}
                  InputProps={{
                    style: { color: "#fff" },
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  InputLabelProps={{ style: { color: "#fff" } }}
                  InputProps={{
                    style: { color: "#fff" },
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="new-password"
                  InputLabelProps={{ style: { color: "#fff" } }}
                  InputProps={{
                    style: { color: "#fff" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="remember" sx={{ color: "white" }} />
                  }
                  label="Remember me"
                  style={{ color: "#fff" }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="terms"
                      sx={{ color: "white" }}
                      checked={agreeToTerms}
                      onChange={(e) => {
                        setAgreeToTerms(e.target.checked);
                        setErrorTerms("");
                      }}
                    />
                  }
                  label={
                    <Typography component="span" style={{ color: "#fff" }}>
                      I agree to all the{" "}
                      <Link to="/" style={{ textDecoration: "none" }}>
                        <Typography
                          component="span"
                          color="white"
                          style={{ display: "inline" }}
                        >
                          Terms
                        </Typography>
                      </Link>
                      {"  and  "}
                      <Link to="/" style={{ textDecoration: "none" }}>
                        <Typography
                          component="span"
                          color="white"
                          style={{ display: "inline" }}
                        >
                          Privacy policy
                        </Typography>
                      </Link>
                    </Typography>
                  }
                />
                {errorTerms && (
                  <Typography variant="body2" color="error">
                    {errorTerms}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container display="flex" justifyContent={"flex-start"} mt={2}>
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                justifyContent={"flex-start"}
                mt={2}
              >
                <Button
                  disabled={isLoading}
                  type="submit"
                  variant="contained"
                  size="medium"
                  sx={{
                    // mt: 3,
                    // mb: 2,
                    backgroundColor: "#00c853",
                    color: "white",
                    "&:hover": { backgroundColor: "#00c853" },
                    fontSize: {
                      xs: "0.8rem",
                      sm: "0.8rem",
                      md: "0.8rem",
                      lg: "0.9rem",
                    },
                  }}
                >
                  Create account
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                justifyContent={"flex-start"}
                mt={2}
              >
                <Button
                  type="button"
                  variant="contained"
                  size="medium"
                  startIcon={<GoogleIcon />}
                  sx={{
                    // mt: 3,
                    // mb: 2,
                    backgroundColor: "#4285f4",
                    color: "white",
                    "&:hover": { backgroundColor: "#4285f4" },
                    fontSize: {
                      xs: "0.8rem",
                      sm: "0.8rem",
                      md: "0.8rem",
                      lg: "0.9rem",
                    },
                  }}
                >
                  Sign in with Google
                </Button>
              </Grid>
              {error && (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  display="flex"
                  justifyContent={"flext-start"}
                  mt={2}
                >
                  <Button
                    type="button"
                    variant="contained"
                    size="large"
                    color="error"
                  >
                    {error}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login" style={{ textDecoration: "none" }}>
                  <Typography color="white" variant="h6" mt={2}>
                    Already have an account? Log in
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default SignupPage;
