import React, { useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import aspectImage from "../../Images/Intropage/pages/aspect.png";
import musicImage from "../../Images/Intropage/pages/music.png";
import backgroundsImage from "../../Images/Intropage/pages/backgrounds.png";
import uploadImage from "../../Images/Intropage/pages/upload.png";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import MusicVideoOutlinedIcon from "@mui/icons-material/MusicVideoOutlined";
import AspectRatioOutlinedIcon from "@mui/icons-material/AspectRatioOutlined";

function PageTwo() {
  // Set the default option to "Upload Your Photos"
  const [currentImage, setCurrentImage] = useState(uploadImage);
  const [hoveredOption, setHoveredOption] = useState("Upload Your Photos");

  // Mapping text options to images
  const optionToImageMap = {
    "Upload Your Photos": uploadImage,
    "Choose Background": backgroundsImage,
    "Select Music": musicImage,
    "Choose Aspect Ratio": aspectImage,
  };

  // Mapping text options to icons
  const optionToIconMap = {
    "Upload Your Photos": (
      <FileDownloadOutlinedIcon sx={{ marginRight: "10px" }} />
    ),
    "Choose Background": (
      <CollectionsOutlinedIcon sx={{ marginRight: "10px" }} />
    ),
    "Select Music": <MusicVideoOutlinedIcon sx={{ marginRight: "10px" }} />,
    "Choose Aspect Ratio": (
      <AspectRatioOutlinedIcon sx={{ marginRight: "10px" }} />
    ),
  };

  // Hover effect styles with the default option highlighted
  const getHoverStyles = (option) => ({
    backgroundColor: hoveredOption === option ? "#13251F" : "transparent",
    // color: hoveredOption === option ? "#62FFB4" : "white",
    cursor: "pointer",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    paddingX: "40px",
    marginBottom: "10px",
    fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
  });

  return (
    <Box sx={{ backgroundColor: "black", minHeight: "100vh", padding: 2 }}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: { xs: "20px", md: "50px" } }}
      >
        {/* Text Content */}
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            color: "white",
            textAlign: { xs: "center", md: "left" },
            padding: { xs: 2, md: 4 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              lineHeight: "normal",
              textAlign: "center",
              marginBottom: "10px",
              width: { xs: "100%", md: "70%" },
              margin: "20px auto",
              fontSize: { xs: "1.4rem", sm: "1.5rem", md: "2rem" },
            }}
          >
            Personalize Your Memorial Videos With Ease
          </Typography>
          <Typography
            variant="body1"
            sx={{
              lineHeight: "normal",
              marginBottom: "20px",
              textAlign: "center",
              width: { xs: "80%", md: "80%" },
              margin: "5px auto",
              fontSize: { xs: "0.9rem", sm: "1rem", md: "1.2rem" },
            }}
          >
            Upload photos of your dear ones, choose from a variety of
            backgrounds and music, and create personalized memorial videos that
            truly honor your loved ones.
          </Typography>
          {/* Options List */}
          <Box
            sx={{
              width: { xs: "80%", md: "80%" },
              margin: "40px auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            {Object.keys(optionToImageMap).map((option, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={getHoverStyles(option)}
                onMouseEnter={() => {
                  setCurrentImage(optionToImageMap[option]);
                  setHoveredOption(option);
                }}
                onMouseLeave={() => setHoveredOption("Upload Your Photos")}
              >
                {optionToIconMap[option]}
                {option}
              </Typography>
            ))}
          </Box>
        </Grid>

        {/* Image Display */}
        <Grid item xs={12} md={5} display={"flex"} justifyContent="center">
          <Box
            sx={{
              width: { xs: "370px", md: "600px" },
              height: "auto",
            }}
          >
            <img
              src={currentImage}
              alt="Preview"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "10px",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PageTwo;
