import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import image from "../../Images/Intropage/pages/dash.png";

function PageFive() {
  return (
    <Box
      sx={{
        backgroundColor: "#13251F",
        color: "white",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: { xs: "10px", md: "20px" },
      }}
    >
      <Grid container spacing={5} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={12}>
          {/* <Typography
            variant="h5"
            sx={{
              lineHeight: "normal",
              textAlign: "center",
              marginBottom: "10px",
              width: { xs: "100%", md: "70%" },
              margin: "20px auto",
              fontSize: { xs: "1.4rem", sm: "1.5rem", md: "2rem" },
            }}
          >
            Why Choose Our Memorial Video Service?
          </Typography> */}
          <Typography
            variant="body1"
            sx={{
              lineHeight: "normal",
              marginBottom: "20px",
              textAlign: "center",
              width: { xs: "60%", md: "60%" },
              margin: "5px auto",
              fontSize: { xs: "1rem", sm: "1.1rem", md: "1.3rem" },
            }}
          >
            Create beautiful memorial videos with personalized touch, ease of
            use, and timely delivery.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          display={"flex"}
          justifyContent="center"
          sx={{ textAlign: "center" }}
        >
          <Box
            sx={{
              width: { xs: "370px", md: "600px" },
              height: "auto",
            }}
          >
            <img
              src={image}
              alt=" here"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "30px",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PageFive;
