import React from "react";
import { Box, Card, CardContent, Typography, IconButton } from "@mui/material";
import { FileDownloadOutlined } from "@mui/icons-material";
import image from "../../Images/SignUpBackground.jpeg";

const DashboardOrderCard = ({ video }) => {
  if (!video) return null;

  const handleDownload = async () => {
    const downloadUrl = video.videoLink;
    const fetchResponse = await fetch(downloadUrl);
    const blob = await fetchResponse.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", video.title);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Card
        sx={{
          bgcolor: "#62FFB444",
          color: "#fff",
          width: "300px",
          margin: "20px",
          borderRadius: "20px",
        }}
      >
        <CardContent>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            py={2}
          >
            <img
              src={image} // Replace with actual image path
              alt="project-thumbnail"
              width="100%"
              style={{ borderRadius: "20px" }}
            />
            <IconButton
              onClick={handleDownload}
              sx={{
                position: "absolute",
                backgroundColor: "#3F806F",
                borderRadius: "50%",
                padding: "10px",
                transition: "background-color 0.3s ease",
              }}
            >
              <FileDownloadOutlined sx={{ fontSize: 40, color: "#ffffff" }} />
            </IconButton>
          </Box>
          <Typography variant="h5" align="left" py={1}>
            {video.title}
          </Typography>
          <Typography variant="body2" align="left" py={1}>
            Order Completed at: {new Date(video.updatedAt).toLocaleDateString()}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DashboardOrderCard;
