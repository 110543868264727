import React from "react";
import {
  AppBar,
  Typography,
  Box,
  Button,
  IconButton,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import HdOutlinedIcon from "@mui/icons-material/HdOutlined";
import PictureInPictureAltIcon from "@mui/icons-material/PictureInPictureAlt";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import logo from "../../Images/logo1.png";
import Dashboardnavbar from "../Dashboard/Dashboardnavbar";
import image from "../../Images/dashboard.png";
import StripeLogo from "../../Images/stripe-logo.png"; // Assuming you have a Stripe logo image
import { Link } from "react-router-dom";
import { usePayment } from "../../Hooks/usePayment"; // Adjust the path as needed
import { PlayArrow } from "@mui/icons-material";

const Checkout = () => {
  const { Payment } = usePayment();
  const handlepayment = async () => {
    Payment();
  };
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Box sx={{ backgroundColor: "#000", color: "#181C1F", minHeight: "100vh" }}>
      {/* Navbar */}
      <AppBar position="static" sx={{ backgroundColor: "#000" }}>
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"space-between"}
          py={2}
        >
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Link to="/">
              <img src={logo} alt="logo" width="300px" />
            </Link>
          </Box>
          <Box>
            <Dashboardnavbar />
          </Box>
        </Box>
      </AppBar>

      <Container>
        {/* First Section (Edit Video) */}
        <Card
          sx={{
            marginTop: 2,
            backgroundColor: "#62FFB417",
            borderRadius: "10px",
          }}
        >
          <CardContent sx={{ display: "flex", alignItems: "center" }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={4} md={3}>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    paddingBottom: "45%", // Maintain aspect ratio
                    // marginBottom: 2,
                  }}
                >
                  {" "}
                  <CardMedia
                    component="img"
                    image={image} // Replace with your video thumbnail
                    alt="First video"
                    sx={{
                      borderRadius: "20px",
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  />
                  <Link to="/edit">
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "#00FF91",
                      }}
                    >
                      <PlayArrow sx={{ fontSize: 30, color: "black" }} />
                    </IconButton>
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Typography variant="h5" component="div" color="primary">
                  First video
                </Typography>
                <Typography variant="body2" color="primary">
                  June 25, 2024
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                md={6}
                display={"flex"}
                justifyContent={"flex-end"}
              >
                <Link to="/edit">
                  <Button
                    variant="contained"
                    startIcon={<EditIcon />}
                    sx={{ backgroundColor: "#ffffff", borderRadius: "10px" }}
                  >
                    Edit video
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* Second Section (Purchase Information) */}
        <Box sx={{ marginTop: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  backgroundColor: "black",
                  padding: 2,
                  minHeight: "80%",
                }}
              >
                <div style={containerStyle}>
                  <IconButton color="primary">
                    <HdOutlinedIcon fontSize="large" />
                  </IconButton>
                </div>
                <Typography
                  variant="h6"
                  component="div"
                  textAlign={"center"}
                  sx={{
                    marginBottom: 2,
                  }}
                  color="primary"
                >
                  High Quality (HD 1080p)
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  textAlign={"center"}
                  sx={{
                    fontSize: {
                      xs: "0.7rem",
                      sm: "0.7rem",
                      md: "0.8rem",
                      lg: "1rem",
                    },
                  }}
                >
                  Download our highest quality for crystal clear video.
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  backgroundColor: "black",
                  padding: 2,
                  minHeight: "80%",
                }}
              >
                <div style={containerStyle}>
                  <IconButton color="primary">
                    <PictureInPictureAltIcon fontSize="large" />
                  </IconButton>
                </div>
                <Typography
                  variant="h6"
                  component="div"
                  textAlign={"center"}
                  sx={{ marginBottom: 2 }}
                  color="primary"
                >
                  Video without watermark
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  textAlign={"center"}
                  sx={{
                    fontSize: {
                      xs: "0.7rem",
                      sm: "0.7rem",
                      md: "0.8rem",
                      lg: "1rem",
                    },
                  }}
                >
                  Get your memorial video without a watermark after making a
                  purchase.
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ backgroundColor: "black", padding: 2 }}>
                <div style={containerStyle}>
                  <IconButton color="primary">
                    <MailOutlineIcon fontSize="large" />
                  </IconButton>
                </div>
                <Typography
                  variant="h6"
                  component="div"
                  textAlign={"center"}
                  sx={{ marginBottom: 2 }}
                  color="primary"
                >
                  Professional Polishing & Convenient Download
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  textAlign={"center"}
                  sx={{
                    fontSize: {
                      xs: "0.7rem",
                      sm: "0.7rem",
                      md: "0.8rem",
                      lg: "0.8rem",
                    },
                  }}
                >
                  Our team polish the final video before emailing you the link.
                  We'll email you a secure download link for easy access and
                  sharing, handling your precious memories with utmost care and
                  confidentiality.
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* Third Section (Proceed to Checkout) */}
        <Box sx={{ marginTop: 4, textAlign: "center" }}>
          <Card sx={{ backgroundColor: "black", padding: 2 }}>
            <CardContent>
              <Grid
                container
                display={"flex"}
                justifyContent={"space-between"}
                spacing={2}
                sx={{ marginBottom: 2 }}
              >
                <Grid item xs={12} md={8}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src={StripeLogo}
                      alt="Stripe"
                      style={{ width: "200px", marginRight: 16 }}
                    />
                    <Typography variant="h5" component="div" color="primary">
                      Shop with Confidence!
                    </Typography>
                    <Typography
                      variant="body2"
                      color="primary"
                      textAlign="start"
                      sx={{
                        fontSize: {
                          xs: "0.7rem",
                          sm: "0.7rem",
                          md: "0.8rem",
                          lg: "1rem",
                        },
                      }}
                    >
                      At AngelicDove, your security is our top priority. That's
                      why we've partnered with Stripe, a leading payment
                      processor trusted by millions worldwide. When you shop
                      with us, you can rest assured that your payment
                      information is protected by state-of-the-art security
                      measures.
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box width={"100%"} justifyContent={"center"}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#00FF91", // Example color
                        color: "#000000", // Text color
                        fontSize: {
                          xs: "0.7rem",
                          sm: "0.7rem",
                          md: "0.8rem",
                          lg: "1rem",
                        },
                      }}
                      onClick={handlepayment}
                    >
                      Proceed to Checkout
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Box>
  );
};

export default Checkout;
