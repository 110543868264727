import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import image from "../../Images/Intropage/page1/3.png";

function PageFour() {
  return (
    <Box
      sx={{
        backgroundColor: "black",
        minHeight: "100vh",
        padding: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: { xs: "20px", md: "55px" } }}
      >
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            color: "rgb(255, 241, 241)",
            textAlign: { xs: "center", md: "left" },
            padding: { xs: 2, md: 4 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              lineHeight: "normal",
              textAlign: "center",
              marginBottom: "10px",
              width: { xs: "100%", md: "70%" },
              margin: "20px auto",
              fontSize: { xs: "1.4rem", sm: "1.5rem", md: "2rem" },
            }}
          >
            Receive your final video via email
          </Typography>
          <Typography
            variant="body1"
            sx={{
              lineHeight: "normal",
              marginBottom: "20px",
              textAlign: "center",
              width: { xs: "80%", md: "80%" },
              margin: "5px auto",
              fontSize: { xs: "0.9rem", sm: "1rem", md: "1.2rem" },
            }}
          >
            After customizing your memorial video, simply purchase it and
            receive the final product via email.
          </Typography>
        </Grid>
        <Grid item xs={12} md={5} display={"flex"} justifyContent="center">
          <Box
            sx={{
              width: { xs: "370px", md: "600px" },
              height: "auto",
            }}
          >
            <img
              src={image}
              alt=" here"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PageFour;
