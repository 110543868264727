import React from "react";
import { Button, Box, AppBar, Toolbar, Link } from "@mui/material";
import mainlogo from "../../Images/logo1.png";
import mobilelogo from "../../Images/logo.png";
import { Link as RouterLink } from "react-router-dom";

function Navbar() {
  return (
    // <AppBar
    //   position="static"
    //   sx={{
    //     display: "flex",
    //     flexDirection: "row",
    //     justifyContent: "flex-start",
    //     backgroundColor: "black",
    //     width: "100vw",
    //     padding: 0, // Remove padding from AppBar
    //     margin: 0, // Remove margin from AppBar
    //   }}
    // >
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
      }}
    >
      <Toolbar
        sx={{
          width: "100vw", // Use full width
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          paddingLeft: "0px", // Remove padding from Toolbar
          paddingBottom: 0, // Remove margin from Toolbar
        }}
      >
        {/* Logo for Desktop */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
          }}
        >
          <img
            src={mainlogo}
            alt="Logo"
            style={{
              maxWidth: "230px",
              padding: "3px",
            }}
          />
        </Box>

        {/* Logo for Mobile */}
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
          }}
        >
          <img
            src={mobilelogo}
            alt="Mobile Logo"
            style={{
              maxWidth: "50px", // Adjust the width for the mobile logo
              padding: "2px",
            }}
          />
        </Box>

        {/* Navigation Links */}
        <Box
          component="nav"
          sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              display: "flex",
              listStyle: "none",
              marginTop: "10px",
              padding: 0,
            }}
          >
            <Link
              component={RouterLink}
              to="/"
              sx={{
                margin: { xs: "5px", sm: "10px" }, // Smaller margin on mobile
                fontFamily: "poppins",
                textDecoration: "none",
                color: "white",
                fontSize: { xs: "0.7rem", sm: "1rem" }, // Smaller font size on mobile
                "&:hover": {
                  color: "white",
                },
              }}
            >
              Features
            </Link>

            <Link
              component={RouterLink}
              to="/"
              sx={{
                margin: { xs: "5px", sm: "10px" }, // Smaller margin on mobile
                fontFamily: "poppins",
                textDecoration: "none",
                color: "white",
                fontSize: { xs: "0.7rem", sm: "1rem" }, // Smaller font size on mobile
                "&:hover": {
                  color: "white",
                },
              }}
            >
              Pricing
            </Link>

            <Link
              component={RouterLink}
              to="/"
              sx={{
                margin: { xs: "5px", sm: "10px" }, // Smaller margin on mobile
                fontFamily: "poppins",
                textDecoration: "none",
                color: "white",
                fontSize: { xs: "0.7rem", sm: "1rem" }, // Smaller font size on mobile
                "&:hover": {
                  color: "white",
                },
              }}
            >
              Support
            </Link>
          </Box>
        </Box>

        {/* Sign In and Get Started Buttons */}
        <Box
          sx={{
            mr: "25px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <Button
            component={RouterLink}
            to="/login"
            sx={{
              margin: "5px",
              borderRadius: "10px",
              fontSize: { xs: "0.5rem", sm: "0.9rem" }, // Smaller font size on mobile
              padding: "3px 12px",
              border: "2px solid white",
              color: "white",
              background: "inherit",
              textDecoration: "none",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
            }}
          >
            Sign In
          </Button>
          <Button
            component={RouterLink}
            to="/signup"
            sx={{
              margin: "5px",
              borderRadius: "10px",
              fontSize: { xs: "0.5rem", sm: "0.9rem" }, // Smaller font size on mobile
              padding: "4px 12px",
              backgroundColor: "#62FFB4",
              border: "2px solid",
              color: "black",
              textDecoration: "none",
              "&:hover": {
                backgroundColor: "#62FFB4", // Same background color on hover
              },
            }}
          >
            Get Started
          </Button>
        </Box>
      </Toolbar>
    </Box>
  );
}

export default Navbar;
