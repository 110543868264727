import React, { useEffect, useState, useContext } from "react";
import { Box, Typography, IconButton, Slider } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useVideoContext } from "../../../Context/VideoContext"; // Adjust the path as needed
import { useAuthContext } from "../../../Hooks/useAuthContext";
import song1 from "../../../Images/Song1.png";
import { useGetAllSongs } from "../../../Hooks/useGetSongs";
import { useSongContext } from "../../../Context/SongContext"; // Adjust the path as needed
import { useGetAllVideos } from "../../../Hooks/useGetAllVideos";
import { PopupWindowContext } from "../../../Context/PopupWindowContext";
import styles from "../../../Styles/AddSong.module.css"; // Ensure this file contains the necessary styles

function AddSong({ component, handleClose }) {
  const { GetVideoRefresh } = useGetAllVideos();
  const { song } = useSongContext();
  const { user } = useAuthContext();
  const { GetSongs } = useGetAllSongs();
  const { videoId } = useVideoContext();
  const { setFormState } = useContext(PopupWindowContext);

  const [loading, setLoading] = useState({});
  const [added, setAdded] = useState({});
  const [playing, setPlaying] = useState(null);
  const [currentTime, setCurrentTime] = useState({});
  const [audio, setAudio] = useState(null);
  const [duration, setDuration] = useState({});

  useEffect(() => {
    GetSongs();
  }, [GetSongs]);

  useEffect(() => {
    if (audio) {
      audio.addEventListener("timeupdate", handleTimeUpdate);
      audio.addEventListener("ended", handleSongEnd);
      audio.addEventListener("loadedmetadata", handleLoadedMetadata);
      return () => {
        audio.removeEventListener("timeupdate", handleTimeUpdate);
        audio.removeEventListener("ended", handleSongEnd);
        audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
        // Cleanup: Pause and reset the audio when the component unmounts
        audio.pause();
        audio.currentTime = 0;
      };
    }
  }, [audio]);

  const handleSongSelect = (song) => {
    setLoading((prev) => ({ ...prev, [song._id]: true }));

    setFormState((prevState) => ({
      ...prevState,
      AddSong: song.SongUrl,
    }));
    setAdded((prev) => ({ ...prev, [song._id]: true }));
    setLoading((prev) => ({ ...prev, [song._id]: false }));
  };

  const handleAddClick = async (song) => {
    if (!user) {
      alert("You must be logged in to update the song");
      return;
    }

    setLoading((prev) => ({ ...prev, [song._id]: true }));

    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/video/${videoId._id}/bg-music`,
        {
          backgroundMusic: song.SongUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      GetVideoRefresh(videoId._id);
      console.log("Song updated successfully");
      handlePauseClick();
      setTimeout(() => {
        handleClose();
      }, 500);
      setAdded((prev) => ({ ...prev, [song._id]: true }));
    } catch (error) {
      console.error("Error updating song:", error);
      alert("Failed to update song");
    } finally {
      setLoading((prev) => ({ ...prev, [song._id]: false }));
    }
  };

  const handlePlayClick = (song) => {
    if (audio) {
      audio.pause();
      setPlaying(null);
    }

    const newAudio = new Audio(song.SongUrl);
    setAudio(newAudio);
    setPlaying(song._id);
    newAudio.play();
  };

  const handlePauseClick = () => {
    if (audio) {
      audio.pause();
    }
    setPlaying(null);
  };

  const handleTimeUpdate = () => {
    setCurrentTime((prev) => ({
      ...prev,
      [playing]: audio.currentTime,
    }));
  };

  const handleSongEnd = () => {
    setPlaying(null);
  };

  const handleLoadedMetadata = () => {
    setDuration((prev) => ({
      ...prev,
      [playing]: audio.duration,
    }));
  };

  const handleSliderChange = (event, newValue, song) => {
    if (audio && playing === song._id) {
      audio.currentTime = newValue;
      setCurrentTime((prev) => ({
        ...prev,
        [song._id]: newValue,
      }));
    }
  };

  return (
    <Box
      width={"100%"}
      display="flex"
      justifyContent="center"
      alignContent={"center"}
    >
      <Box
        height="280px"
        pt={1}
        mb={2}
        sx={{
          width: {
            xs: component === "wizard" ? "92vw" : "100vw",
            md: "520px",
          },
          borderRadius: "10px",
        }}
        display="flex"
        justifyContent="center"
        alignContent={"center"}
        backgroundColor="#14181B"
      >
        <Box
          className={styles.songList}
          width={"90%"}
          sx={{ padding: { xs: 0, sm: 1, md: "0 20px 40px 20px" } }}
        >
          {song &&
            song.map((song, index) => (
              <Box
                key={index}
                className={styles.songItem}
                sx={{ gap: { sx: 0, md: 3 } }}
              >
                <Box className={styles.songDetails}>
                  <img
                    src={song1}
                    alt={song.SongName}
                    className={styles.songImage}
                  />
                  <Box className="song-text">
                    <Typography
                      variant="body1"
                      className={styles.songTitle}
                      sx={{
                        fontSize: {
                          xs: "0.8rem",
                          sm: "1rem",
                        },
                      }}
                    >
                      {song.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={styles.songArtist}
                      sx={{
                        fontSize: {
                          xs: "0.7rem",
                          sm: "1rem",
                        },
                      }}
                    >
                      {song.artist}
                    </Typography>
                  </Box>
                </Box>
                <Box className={styles.songControls}>
                  <Slider
                    sx={{
                      width: { xs: "45px", sm: "70px", md: "100px" },
                      color: "#62FFB4",
                      "& .MuiSlider-thumb": {
                        height: "14.83px",
                        width: "4.45px",
                        backgroundColor: "#62FFB4",
                        borderRadius: "2px",
                        boxShadow: "none",
                      },
                      "& .MuiSlider-track": {
                        backgroundColor: "#62FFB4",
                        height: "1.83px",
                      },
                      "& .MuiSlider-rail": {
                        backgroundColor: "#62FFB4",
                      },
                    }}
                    min={0}
                    max={duration[song._id] || 100}
                    value={currentTime[song._id] || 0}
                    onChange={(event, newValue) =>
                      handleSliderChange(event, newValue, song)
                    }
                  />
                  <Typography
                    variant="body2"
                    className={styles.songDuration}
                    sx={{
                      fontSize: {
                        xs: "0.7rem",
                        sm: "1rem",
                      },
                    }}
                  >
                    {song.duration}
                  </Typography>
                  <IconButton
                    className={styles.songIcon}
                    onClick={
                      playing === song._id
                        ? handlePauseClick
                        : () => handlePlayClick(song)
                    }
                    sx={{ padding: { xs: "0", sm: "1" } }}
                  >
                    {playing === song._id ? (
                      <PauseIcon
                        fontSize="large"
                        sx={{
                          color: "#62FFB4",
                          fontSize: {
                            xs: "1.8rem",
                            sm: "1.8rem",
                          },
                        }}
                      />
                    ) : (
                      <PlayArrowIcon
                        fontSize="medium"
                        sx={{
                          color: "white",
                          // border: "2px solid #FFFFFF",
                          // borderRadius: "50%",

                          fontSize: {
                            xs: "1.5rem",
                            sm: "1.8rem",
                          },

                          "&:hover": {
                            color: "#62FFB4", // Change color on hover
                            borderColor: "#62FFB4", // Optionally change border color on hover as well
                          },
                        }}
                      />
                    )}
                  </IconButton>
                  <IconButton
                    className={styles.songIcon}
                    onClick={() => {
                      component === "wizard"
                        ? handleSongSelect(song)
                        : handleAddClick(song);
                    }}
                    sx={{ padding: { xs: "0.5", sm: "1" } }}
                  >
                    {loading[song._id] ? (
                      <CircularProgress size={24} sx={{ color: "#62FFB4" }} />
                    ) : added[song._id] ? (
                      <CheckIcon sx={{ color: "#62FFB4" }} />
                    ) : (
                      <AddIcon
                        sx={{
                          color: "white",
                          border: "2px solid #FFFFFF",
                          borderRadius: "50%",
                          fontSize: {
                            xs: "1.2rem",
                            sm: "1.7rem",
                          },

                          "&:hover": {
                            color: "#62FFB4", // Change color on hover
                            borderColor: "#62FFB4", // Optionally change border color on hover as well
                          },
                        }}
                      />
                    )}
                  </IconButton>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
}

export default AddSong;
