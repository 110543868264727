import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useLogin } from "../../Hooks/useLogin";
import GoogleIcon from "@mui/icons-material/Google";
import "../../Styles/SignupPage.css";
import Loader from "../LoadingScreen/Loader";

function LoginPage() {
  const { error, isLoading, login } = useLogin();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false); // Add state for terms and conditions
  const [errorTerms, setErrorTerms] = useState(""); // Add state for terms error message
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agreeToTerms) {
      setErrorTerms("You must agree to the terms and conditions.");
      return;
    }

    const success = await login(email, password);
    if (success) {
      navigate("/dashboard");
    }
  };
  return (
    <div className="background">
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2,
            borderRadius: 2,
          }}
        >
          <Typography component="h1" variant="h4" sx={{ color: "#fff" }}>
            LOGIN
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 3 }}
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2} display="flex" justifyContent="center">
              <Grid item xs={12} sm={8}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  InputLabelProps={{ style: { color: "#fff" } }}
                  InputProps={{
                    style: {
                      color: "#fff",
                      backgroundColor: "rgba(0, 0, 0, 0.1)", // Custom background color
                    },
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  InputLabelProps={{ style: { color: "#fff" } }}
                  InputProps={{
                    style: {
                      color: "#fff",
                      backgroundColor: "rgba(0, 0, 0, 0.1)", // Custom background color
                    },
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={8}>
                <FormControlLabel
                  control={
                    <Checkbox value="remember" sx={{ color: "white" }} />
                  }
                  label="Remember me"
                  style={{ color: "#fff" }}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="terms"
                      sx={{ color: "white" }}
                      checked={agreeToTerms}
                      onChange={(e) => {
                        setAgreeToTerms(e.target.checked);
                        setErrorTerms("");
                      }}
                    />
                  }
                  label={
                    <span style={{ color: "#fff" }}>
                      I agree to all the{" "}
                      <Link to="#" style={{ color: "inherit" }}>
                        Terms
                      </Link>{" "}
                      and{" "}
                      <Link to="#" style={{ color: "inherit" }}>
                        Privacy policy
                      </Link>
                    </span>
                  }
                />
                {errorTerms && (
                  <Typography variant="body2" color="error">
                    {errorTerms}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              display="flex"
              justifyContent="center"
              mt={2}
            >
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                justifyContent="flex-start"
              >
                <Button
                  disabled={isLoading}
                  type="submit"
                  variant="contained"
                  size="medium"
                  sx={{
                    backgroundColor: "#00c853",
                    color: "white",
                    "&:hover": { backgroundColor: "#00c853" },
                    fontSize: {
                      xs: "0.9rem",
                      sm: "0.8rem",
                      md: "0.8rem",
                      lg: "0.9rem",
                    },
                  }}
                >
                  Login
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                justifyContent="flex-start"
              >
                <Button
                  type="button"
                  size="medium"
                  variant="contained"
                  startIcon={<GoogleIcon />}
                  sx={{
                    backgroundColor: "#4285f4",
                    color: "white",
                    "&:hover": { backgroundColor: "#4285f4" },
                    fontSize: {
                      xs: "0.7rem",
                      sm: "0.7rem",
                      md: "0.8rem",
                      lg: "0.9rem",
                    },
                  }}
                >
                  Sign in with Google
                </Button>
              </Grid>
              {error && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  display="flex"
                  justifyContent={"flex-start"}
                >
                  <Button
                    type="button"
                    variant="contained"
                    size="small"
                    color="error"
                  >
                    {error}
                  </Button>
                </Grid>
              )}
              {isLoading && (
                <Grid
                  item
                  xs={12}
                  sm={8}
                  display="flex"
                  justifyContent="center"
                >
                  <Loader text="Authenticating ..." />
                </Grid>
              )}
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/signup" style={{ textDecoration: "none" }}>
                  <Typography variant="h6" color="white" mt={2}>
                    Don't have an account? Sign up
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default LoginPage;
